define('gemini/controllers/signin', ['exports', 'gemini/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    email: "",
    password: "",

    isLoading: false,
    appLogo: "/assets/images/logo_" + _environment.default.CURRENT_APP + ".png",
    backEndUrl: _environment.default.backEndUrl,

    error: "",
    firebaseApp: Ember.inject.service(),

    // beforeModel: function() {
    //     if (this.get("session") !== undefined && this.get("session").isAuthenticated) {
    //         console.log(this.get("session"));
    //         console.log("signed-in");
    //         return this.transitionTo('index');
    //     }
    // },


    actions: {
      signIn: function signIn() {
        this.set('isLoading', true);
        var controller = this;

        if (this.get("email") === "" || this.get("password") === "") {
          controller.set('isLoading', false);
          controller.set('error', "Please enter an email address and password.");
          return;
        }

        var data = { "email": this.get("email"), "password": this.get("password"), "device": "ADMIN" };
        Ember.$.ajax({
          method: "POST",
          url: this.backEndUrl + "auth/sign-in",
          data: data,
          dataType: "json",
          success: function success(returnData) {
            var token = returnData.token;
            if (token === undefined) {
              controller.set('isLoading', false);
              controller.set('error', "Could not sign in.");
              return;
            }

            // NOT WORKING (probably because firebase sdk is outdated?)
            // let firebase = this.get('firebaseApp');
            // console.log(firebase, firebase.auth(), firebase.auth().Auth);
            // firebase.auth().setPersistence(firebase.auth().Auth.Persistence.SESSION).then(() => {
            //   firebase.auth().signInWithCustomToken(token).then(result => {
            //     console.log(result);
            //   }).catch(e => {
            //     console.error(e);
            //   });
            // });


            // //  OLD:
            controller.get('session').open('firebase', {
              provider: 'custom',
              token: token
            }).then(function () {
              controller.set('email', null);
              controller.set('password', null);
              controller.set('isLoading', false);
              controller.transitionToRoute('app');
            });
            // //  \OLD

          }
        }).fail(function (jqxhr) {
          var response = jqxhr.responseJSON;
          var reason = "Could not sign in.";
          if (response && response.e === 3) {
            var waitUntil = new Date(response.time);
            var now = new Date();
            var difference = waitUntil.getTime() - now.getTime();
            var minutes = Math.max(0, Math.ceil(difference / 1000 / 60));
            reason = "Too many wrong attempts. Please wait " + minutes + " minute" + (minutes !== 1 ? "s" : "") + ".";
          }
          controller.set('isLoading', false);
          controller.set('error', reason);
        });

        // this.get('session').open('firebase', {
        //     provider: 'password',
        //     email: this.get('email') || '',
        //     password: this.get('password') || '',
        // }).then(() => {
        //     controller.set('email', null);
        //     controller.set('password', null);
        //     this.set('isLoading', false);
        //     controller.transitionToRoute('app');
        // }, (error) => {
        //   console.log(error);
        //     this.set('isLoading', false);
        //     this.set('error', "Could not sign in.");
        // });
      }
    }
  });
});