define('gemini/controllers/app/settings/descriptions', ['exports', 'ember-i18n', 'gemini/config/environment'], function (exports, _emberI18n, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    i18n: Ember.inject.service(),
    showField: "descriptions",

    activeDescription: null,
    activeDescriptions: _environment.default.settingsPageActiveDescriptions,
    feedback: _environment.default.modules.feedback,

    descriptions: [{
      groupName: "live",
      options: [{
        url: "info/circle-live-winddirection",
        name: "live.winddirection",
        feedback: "feedback/circle-live-winddirection"
      }, {
        url: "info/circle-live-windspeed",
        name: "live.windspeed",
        feedback: "feedback/circle-live-windspeed"
      }, {
        url: "info/circle-live-households",
        name: "live.households",
        feedback: "feedback/circle-live-households"
      }, {
        url: "info/circle-live-co2",
        name: "live.co2",
        feedback: "feedback/circle-live-co2"
      }, {
        url: "info/circle-live-fulload",
        name: "live.fulload",
        feedback: "feedback/circle-live-fulload"
      }, {
        url: "info/circle-live-lamps",
        name: "live.lamps",
        feedback: "feedback/circle-live-lamps"
      }, {
        url: "info/circle-live-turbine-status",
        name: "live.turbine-status",
        feedback: "feedback/turbine-status"
      }, {
        url: "info/circle-live-waveheight",
        name: "live.waveheight",
        feedback: "feedback/circle-live-waveheight"
      }]
    }, {
      groupName: "history",
      options: [{
        url: "info/circle-history-production",
        name: "history.production",
        feedback: "feedback/circle-history-production"
      }, {
        url: "info/circle-history-hours",
        name: "history.hours",
        feedback: "feedback/circle-history-hours"
      }, {
        url: "info/circle-history-households",
        name: "history.households",
        feedback: "feedback/circle-history-households"
      }, {
        url: "info/circle-history-fulload",
        name: "history.fulload",
        feedback: "feedback/circle-history-fulload"
      }, {
        url: "info/circle-history-co2",
        name: "history.co2",
        feedback: "feedback/circle-history-co2"
      }]
    }, {
      groupName: "other",
      options: [{
        url: "info/privacy",
        name: "other.privacy",
        feedback: "feedback/privacy"
      }, {
        url: "info/disclaimer",
        name: "other.disclaimer",
        feedback: "feedback/disclaimer"
      }, {
        url: "info/licenses",
        name: "other.licenses",
        feedback: "feedback/licenses"
      }]
    }, {
      groupName: "status",
      options: [{
        url: "info/circle-status-wind-high",
        name: "status.wind-high",
        feedback: "feedback/circle-status-wind-high"
      }, {
        url: "info/circle-status-wind-low",
        name: "status.wind-low",
        feedback: "feedback/circle-status-wind-low"
      }, {
        url: "info/circle-status-maintenance",
        name: "status.maintenance",
        feedback: "feedback/circle-status-maintenance"
      }, {
        url: "info/circle-status-environment",
        name: "status.environment",
        feedback: "feedback/circle-status-environment"
      }, {
        url: "info/circle-status-ice",
        name: "status.ice",
        feedback: "feedback/circle-status-ice"
      }]
    }],

    actions: {
      loadDescription: function loadDescription(description) {
        this.set("activeDescription", description);
      }
    },

    descriptionsSet: Ember.on('init', function () {
      var _this = this;
      this.get('descriptions').forEach(function (group, groupKey) {

        var hasVisibleOptions = false;
        group.options.forEach(function (option, optionKey) {
          var path = 'descriptions.' + groupKey + ".options." + optionKey;
          if (_this.get('activeDescriptions').indexOf(option.name) === -1) {
            _this.set(path, null);
          } else {
            _this.set(path + ".i18n", _this.get('i18n'));
            _this.set(path + ".name", (0, _emberI18n.translationMacro)("settings.descriptions." + option.name));
            hasVisibleOptions = true;
          }
        });

        var path = 'descriptions.' + groupKey;
        if (hasVisibleOptions) {
          _this.set(path + ".i18n", _this.get('i18n'));
          _this.set(path + ".groupName", (0, _emberI18n.translationMacro)("settings.descriptions.groups." + group.groupName));
        } else {
          _this.set(path + ".groupName", null);
        }
      });
    })
  });
});